<template lang="pug">

</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TaxPayersIndex',
});
</script>

<style lang="stylus"></style>
